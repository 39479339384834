

import React from 'react' ;
import { Announcements } from "../components/announcements/Announcements";
import { Categories } from "../components/categories/Categories";
import { Footer } from "../components/footer/Footer";
import { Navbar } from "../components/navbar/Navbar";
import { Newsletter } from "../components/newsletter/Newsletter";
import { Products } from "../components/products/Products";


export const Home = () => {
    return (
        <div>
            <Announcements/>
            <Navbar/>
            <Categories/>
            <Products/>
                      
        </div>
    )
};

