


import React from 'react';
import "./product.css";
import { Add, Remove } from '@mui/icons-material';
import { Newsletter } from '../../components/newsletter/Newsletter';
import { Footer } from '../../components/footer/Footer'; 
import { Navbar } from "../../components/navbar/Navbar"

export const ProductPage = () => {
  return (
    <>
    <Navbar/>
    <div className='pContainer'>
        <div className="pWrapper">
            <div className="pImgContainer">
                <img src="https://i.ibb.co/S6qMxwr/jean.jpg" alt="" className="pImg" />

            </div>
            <div className="pInfoContainer">
                <h1 className="pTitle">Denim Jumpsuit</h1>
                <p className="pDesc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                    venenatis, dolor in finibus malesuada, lectus ipsum porta nunc, at
                    iaculis arcu nisi sed mauris. Nulla fermentum vestibulum ex, eget
                    tristique tortor pretium ut. Curabitur elit justo, consequat id
                    condimentum ac, volutpat ornare.

                </p>
                <span className="pPrice">$ 20</span>
                <div className="pFilterContainer">
                    <div className="pFilter">
                        <span className="pFilterTitle">Color</span>
                        <div className="pFilterColor" color='black'></div>
                        <div className="pFilterColor" color='darkblue'></div>
                        <div className="pFilterColor" color='gray'></div>
                    </div>
                    <div className="pFilter">
                        <span className="pFilterTitle">Size</span>
                        <select name="" id="" className="pFilterSize">
                        
                            <option value="" className="pFilterSizeOption">S</option>
                            <option value="" className="pFilterSizeOption">M</option>
                            <option value="" className="pFilterSizeOption">L</option>
                            <option value="" className="pFilterSizeOption">XL</option>

                            
                        </select>
                       
                    </div>
                </div>
                <div className="pAddContainer">
                    <div className="pAmountContainer">
                        <Remove/>
                        <span className="pAmount">1</span>
                        <Add/>
                        <button className="pButton">Add to cart</button>
                    </div>
                </div>
            </div>
        </div>
        


    </div>
   
    </>
  );
};
