
import React from 'react';
import "./Announcements.css";

export const Announcements = () => {
    return (
      <div classname="aContainer">
        <div className="aheader">Super Deal! Free Shipping on Orders Over $50</div>
        
      </div>
    )
};
