
import React from 'react';
import { FavoriteBorder, Search, ShoppingCart } from '@mui/icons-material'; 
import "./product.css";

export const Product = ({ item }) => {
  return (
    <>
    <div className='pContainer'>  
            <div className="pCircle"></div>
            <img src={item.img} alt="" className="pImage" />
            <div className="pInfo">
                <div className="pIcon">
                    <ShoppingCart/>
                </div>
                <div className="pIcon">
                    <Search/>                   
                </div>
                <div className="pIcon">
                    <FavoriteBorder/>                  
                </div>
            </div>
    </div>
    </>
  )
};
