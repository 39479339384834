
import React from 'react';
import "./login.css";
import { Navbar } from "../../components/navbar/Navbar";

export const Login = () => {
  return (
    <>
    <Navbar/>
    <div className='lContainer'>
        <div className="lWrapper">
            <h1 className="lTitle">Sign In</h1>
            <form action="" className="lForm">
                <input type="text" placeholder='username' className="lInput" />
                <input type="text" placeholder='password' className="lInput" />
                <button className="lButton">Login</button>
                <a href="" className="lLink">Do you remember your password</a>
                <a href="" className="lLInk">Create new account</a>
            </form>
        </div>
    </div>
    </>
  )
}


