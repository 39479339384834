

import React from 'react';
import "./register.css";
import { Navbar } from "../../components/navbar/Navbar";

export const Register = () => {
  return (
    <>
    <Navbar/>
    <div className='rContainer'>
        <div className="rWrapper">
            <h1 className="rTitle">Create an Account </h1>
            <form action="" className="rForm">
                <input type="text" className="rInput" placeholder='name' />
                <input type="text" className="rInput" placeholder='last name' />
                <input type="text" className="rInput" placeholder='username' />
                <input type="text" className="rInput" placeholder='email' />
                <input type="text" className="rInput" placeholder='password' />
                <input type="text" className="rInput" placeholder='confirm password' />
                <span className="rAgreement">
                    By creating an account, I consent to the processing of my personal
                    data in accordance with the <b>PRIVACY POLICY</b>
                </span>
                <button className="rButton">Create</button>
            </form>
        </div>
    </div>
    </>
  )
}


