

import React from 'react';
import "./categories.css";
import { categories } from '../../data';
import { CategoryItem } from '../categoryItem/CategoryItem';

export const Categories = () => {
  return (
    <div className='cContainer'>

        {categories.map( (item) => (
        <CategoryItem item={item} key={item.id} />

        ))}


    </div>
  );
};
