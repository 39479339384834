


import React from 'react';
import "./navbar.css";
import { Search, ShoppingCart } from '@mui/icons-material';
import { Link } from "react-router-dom";
import Badge from '@mui/material-next/Badge';

export const Navbar = () => {
    return (
        <div className='nContainer'>
            <div className="nWrapper">
                <div className="nLeft">
                    <span className="nLang">
                        EN
                    </span>
                    <div className="nSContainer">
                        <input type="text" className="nInput" placeholder='Search' />
                        < Search style={{ color: "gray", fontSize: 16 }}/>
                    </div>
                </div>
                <div className="nCenter">
                    <div className="nLogo">
                        <Link to= "/" style={{ textDecoration:"none", color:"gray" }}>                   
                            Gray                 
                        </Link>                       
                    </div>

                </div>
                <div className="nRight">
                    <div className="nItems">
                        <Link to= "/register" style={{ textDecoration:"none", color:"black" }}>                   
                            Register                  
                        </Link>
                    </div>
                    < div className="nItems">
                        <Link to="/login" style={{ textDecoration:"none", color:"black" }}>Sign In</Link>
                    </div>
                    <div className="nItems">
                    <Badge badgeContent={4} color="primary">
                      
                            <ShoppingCart/>
                 
                    </Badge>

                    </div>

                </div>
            </div>
        </div>
    )
}
