
import { BrowserRouter,Route,Routes } from  "react-router-dom";
import { ProductPage } from "../src/pages/product/ProductPage";
import { ProductsPage } from "../src/pages/products/ProductsPage";
import { Login } from "../src/pages/login/Login"; 
import { Register } from "../src/pages/register/Register";
import { Cart } from "../src/pages/cart/Cart";
import { Home } from "../src/pages/Home";

function App() {
  return (
    <BrowserRouter>
    <Routes>

      <Route index element={ <Home/>} />
      <Route path="/" element={ <Home/>}/>
      <Route path="/ProductPage" element={<ProductPage/>}/>
      <Route path="/ProductsPage" element={<ProductsPage/>}/>
      <Route path="/Login" element={<Login/>} />
      <Route path="/Register" element={<Register/>}/>
      <Route path="/Cart" element={<Cart/>}/>

    </Routes>


    </BrowserRouter>
  );
};

export default App;
