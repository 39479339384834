


import React from 'react';
import "./products.css";
import { popularProducts } from '../../data';
import { Product } from '../product/Product';

export const Products = () => {
  return (
    <>
    <div className='pContainer'> 
        {popularProducts.map( (item) => (
          <Product item={item} key={item.id}/>
        ))};
    </div>
    </>
  )
};
