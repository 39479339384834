


export const categories = [
    {
        id: 1,
        img: "https://images.pexels.com/photos/4427430/pexels-photo-4427430.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        title: "SUITS!",
    },
    {
        id: 2,
        img: "https://images.pexels.com/photos/297933/pexels-photo-297933.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        title: "SHIRTS",
    },
    {
        id: 3,
        img: "https://images.pexels.com/photos/267320/pexels-photo-267320.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        title: "SHOES",
    },
];

export const popularProducts = [
    {
        id:1,
        img:"https://th.bing.com/th/id/OIP.nY2l-LbhGqcz9BIt8MEMjAHaE8?rs=1&pid=ImgDetMain",
    },
    {
        id:2,
        img:"https://fxposed.co.uk/Images/No%20Watermark/Suits/SUIT-X6680-1-TAN/11.JPG",
    },
    {
        id:3,
        img:"https://happygentleman.com/wp-content/uploads/2019/11/cavani-ascari-men-s-3-piece-oak-brown-tweed-check-suit-480x480.jpg",
    },
    {
        id:4,
        img:"https://images-na.ssl-images-amazon.com/images/I/811dduxFXYL._AC_UL1500_.jpg",
    },
    {
        id:5,
        img:"https://th.bing.com/th/id/OIP.Rw9K5q9EoYeYSbuEclj4IAHaJ4?rs=1&pid=ImgDetMain" ,
    },

]