
import React from 'react';
import "./categoryItem.css";
import { Link } from "react-router-dom";

export const CategoryItem = ({ item }) => {
  return (
    <div className='cContainer'>

        <img src={item.img} alt="" />
        <div className="cInfo">
            <h1 className="cTitle">{item.title}</h1>
            <button className="cButton">
              <Link to="/ProductsPage" style={{ textDecoration:"none", color:"grey" }}>Shop Now !</Link>
            </button>
        </div>
      </div>
        

  )
}
