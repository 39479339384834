


import React from 'react';
import "./products.css";
import { Announcements } from '../../components/announcements/Announcements';
import { Navbar } from "../../components/navbar/Navbar";
import { Products } from "../../components/products/Products";
import { Newsletter } from '../../components/newsletter/Newsletter';
import { Footer } from "../../components/footer/Footer";
import { NewspaperOutlined } from '@mui/icons-material';


export const ProductsPage = () => {
  return (
    <>
    <Announcements/>
    <Navbar/>
    <div className='pContainer'>
        <h1 className="pTitle">Suits</h1> 
        <div className="pFilterContainer">
            <div className="pFilter">
                <span className="pFilterText">Filter Products</span>
                <select name="" id="" className="pSelect">
                    <option value="">White</option>
                    <option value="">Black</option>
                    <option value="">Red</option>
                    <option value="">Blue</option>
                    <option value="">Yellow</option>
                </select>
                <select name="" id="" className="pSelect">
                    <option value="">S</option>
                    <option value="">M</option>
                    <option value="">L</option>
                    <option value="">XL</option>
                </select>
            </div>
            <div className="pFilter">
                <span className="pFilterText">Sort Products:</span>
                <select name="" id="" className="pSelect">
                    <option value="">Newest</option>
                    <option value="">Price (asc)</option>
                    <option value="">Price (desc)</option>
                </select>
            </div>
        </div>
    </div>
    <Products/>

    
    </>

  )
}



