

import React from 'react';
import "./cart.css";
import { Add, Remove } from '@mui/icons-material';
import { Footer } from '../../components/footer/Footer';
import { Navbar } from "../../components/navbar/Navbar";

export const Cart = () => {
  return (
    <>
    <Navbar/>
    <div className='cContainer'>
        <div className="cWrapper">
            <h1 className="cTitle">Your Bag</h1>
            <div className="cTop">
                <button className="cTopButton">Continue Shopping</button>
                <div className="cTopTexts">
                    <span className="cTopText">Shopping bag</span>
                    <span className="cTopText">Wishlist</span>
                </div>
                <button className="cTopButton">Checkout Now</button>

            </div>
            <div className="cBottom">
                <div className="cInfo">
                    <div className="cProduct">
                        <div className="cProductDetail">
                            <img src="https://hips.hearstapps.com/vader-prod.s3.amazonaws.com/1614188818-TD1MTHU_SHOE_ANGLE_GLOBAL_MENS_TREE_DASHERS_THUNDER_b01b1013-cd8d-48e7-bed9-52db26515dc4.png?crop=1xw:1.00xh;center,top&resize=480%3A%2A" alt="" className="cImg" />
                            <div className="cDetails">
                                <span className="cProductName">
                                    <b>Product:</b> Jessie Thunder Shoes
                                </span>
                                <span className="cProductId">
                                    <b>ID:</b> 93030393020
                                </span>
                                <div color='black'/>
                                <span className="cProductSize">
                                    <b>Size:</b> 37.5
                                </span>
                            </div>

                        </div>
                        <div className="cPriceDetail">
                            <div className="cProductAmountContainer">
                                <Add/>
                                <span className="cProductAmount">2</span>
                                <Remove/>
                            </div>
                            <div className="cProductPrice">$ 30</div>

                        </div>
                    </div>
                </div>
                <div className="cSummary">
                    <div className="cSummaryTitle">Order Summary</div>
                    <div className="cSummaryItem">
                        <span className="cSummaryItemText">Subtotal</span>
                        <span className="cSummaryItemPrice">$ 80</span>
                    </div>
                    <div className="cSummaryItem">
                        <span className="cSummaryItemText">Estimated Shipping</span>
                        <span className="cSummaryItemPrice">$ 6</span>
                    </div>
                    <div className="cSummaryItem">
                        <span className="cSummaryItemText">Shipping discount</span>
                        <span className="cSummaryItemPrice">$ -4.9</span>
                    </div>
                    <div className="cSummaryItem" type="total">
                        <span className="cSummaryItemText">Total</span>
                        <span className="cSummaryItemPrice">$ 80</span>
                    </div>
                    <button className="cButton">CheckOut Now</button>
                </div>
            </div>
        </div>
    </div>
    </>
  );
};
